<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card class="elevation-1">
                    <div class="dash-container">
                        <div class="dash-company-info">
                            <div class="intro">
                                <h1>{{$auth.company('name')}}</h1>
                                <p class="text--lighten-5 company-slogan" style="font-size: 20px;">
                                    {{$auth.company('slogan').length ?
                                    $auth.company('slogan') : 'Build your business, grow your wealth'}}...
                                </p>

                            </div>
                            <div class="navigate-to">
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import cookie from 'js-cookie'

    export default {
        data: () => ({
            items: [
                {header: 'Today'},
                {
                    avatar: 'https://image.flaticon.com/icons/svg/1089/1089129.svg',
                    title: 'Teacher',
                    subtitle: "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?"
                },
                {divider: true, inset: true},
                {
                    avatar: 'https://image.flaticon.com/icons/svg/201/201608.svg',
                    title: 'Students <span class="grey--text text--lighten-1">4</span>',
                    subtitle: "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend."
                },
                {divider: true, inset: true},
                {
                    avatar: 'https://image.flaticon.com/icons/svg/374/374971.svg',
                    title: 'Guardians',
                    subtitle: "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?"
                },
                {divider: true, inset: true},
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
                    title: 'Birthday gift',
                    subtitle: "<span class='text--primary'>Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?"
                },
                {divider: true, inset: true},
                {
                    avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
                    title: 'Recipe to try',
                    subtitle: "<span class='text--primary'>Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos."
                }
            ],
            model: 'tab-0',
            tabItems: [
                'Payments'
            ],
            options: {
                chart: {
                    width: 500
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                }
            },
            series: [{
                name: 'Class 10',
                data: [20, 41, 35, 51, 49, 62, 69, 91, 148]
            }, {
                name: 'Class 9',
                data: [50, 100, 20, 60, 70, 85, 50, 20, 148]
            }, {
                name: 'Class 8',
                data: [70, 80, 90, 20, 30, 55, 10, 70, 128]
            }],
            chartOptions: {
                chart: {
                    // width: '50%',
                    // zoom: {
                    //     enabled: false
                    // }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                // title: {
                //     text: '',
                //     align: 'left'
                // },
                grid: {
                    row: {
                        colors: ['#efeeee', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                },
            },

        }),
        computed: {
            ...mapState(['user', 'members', 'countData']),
        },
        mounted() {
        },
        methods: {
            viewProfile() {
                this.$router.push({name: 'my-account'});
            }
        },

        beforeDestroy() {
            this.$events.remove('notification')
        },

    }
</script>
<style lang="scss">

    .dash-container {
        /*padding: 0 !important;*/
        .info-tab-section {
            padding-bottom: 20px;
            .theme--light {
                background-color: transparent !important;
            }
        }
    }

    .v-tabs__item {
        color: #888;
    }

    .v-tabs__item--active {
        color: #1976d2 !important;
        font-weight: bold;
    }

    .chart-container {
        padding: 20px 0 !important;
        margin-left: -60px;
        min-height: 330px;

    }

    .app-features {
        display: flex;
        min-height: 120px;
        background-color: white;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        .feature-icon {
            flex: 1.3;
            align-items: center;
            justify-content: center;
            display: flex;
            /*background-color: red;*/
        }
        .feature-content {
            flex: 3;
            background-color: white;
            /*padding: 15px 15px;*/
            display: flex;
            align-items: center;
            justify-content: center;
            .content {
                p {
                    color: #777;
                    line-height: 15px;
                    font-size: 13px;
                }
            }
            h3 {
                font-size: 18px;
            }
            h2 {
                color: #555;
                line-height: 14px;
                font-size: 20px;
                font-weight: 300;
            }
        }
        &.f-books {
            .feature-icon {
                background: -webkit-linear-gradient(45deg, #FF5252 0%, #f48fb1 100%);
                background: linear-gradient(45deg, #FF5252 0%, #f48fb1 100%);
            }
            h3 {
                color: #FF5252;
            }
        }
        &.f-sms {
            .feature-icon {
                background: -webkit-linear-gradient(45deg, #43A047 0%, #1de9b6 100%);
                background: linear-gradient(45deg, #43A047 0%, #1de9b6 100%);
            }
            h3 {
                color: #43A047;
            }
        }
        &.f-converter {
            .feature-icon {
                background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
                background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
            }
            h3 {
                color: #0288d1;
            }
        }
        &.f-collection {
            .feature-icon {
                background: -webkit-linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
                background: linear-gradient(45deg, #b199dd 0%, #a389d3 100%);
            }
            h3 {
                color: #a389d3;
            }
        }
    }

    .background-amount {
        background: white;
        background: -webkit-linear-gradient(45deg, white 0%, #dddddd 100%);
        background: linear-gradient(45deg, white 0%, #dddddd 100%);
    }

    .background-converter {
        background: #FF5252;
        background: -webkit-linear-gradient(45deg, #FF5252 0%, #f48fb1 100%);
        background: linear-gradient(45deg, #FF5252 0%, #f48fb1 100%);
    }

    .background-books {
        background: #0288d1;
        background: -webkit-linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
        background: linear-gradient(45deg, #0288d1 0%, #26c6da 100%);
    }

    .background-sms {
        background: #43A047;
        background: -webkit-linear-gradient(45deg, #43A047 0%, #1de9b6 100%);
        background: linear-gradient(45deg, #43A047 0%, #1de9b6 100%);
    }

    .dash-container {
        min-height: 100px;
        width: 90%;
        /*padding: 40px 0 0 0;*/
        margin: 0 auto;
        div.dash-company-info {
            padding-top: 20px;
            display: flex;
            padding-bottom: 10px;
        }
        h1 {
            color: #555;
            font-weight: 300;
            + p {
                color: #888;
                font-size: 14px;
            }
        }
        .navigate-to {
            flex: 1.5;
            padding-top: 15px;
            ul li {
                list-style: none;
                display: inline;
                a {
                    display: inline-block;
                    border: 0.15em solid #c9c9c9;
                    margin-right: 10px;
                    height: 25px;
                    font-size: 10px;
                    width: 25px;
                    text-align: center;
                    line-height: 25px;
                    border-radius: 50px;
                    text-decoration: none;
                    &:hover {
                        i {
                            color: #333 !important;
                        }
                    }
                }
            }
        }
        .intro {
            flex: 3;
        }
    }

    .profile-user-info {
        display: flex;
        .profile-image {
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
            border-radius: 200px;
            overflow: hidden;
            flex: 1;
            border: 1px solid white;
            img {
                margin: 0 auto 0 -10px;
                padding: 0;
                width: 118%;
            }
            width: 100px;
            height: 106px;
            /*background-color: red;*/
        }
        .profile-info {
            ul {
                margin: 0;
                padding: 0;
            }
            flex: 2;
            color: #555;
            padding-left: 15px;
            h3 {
                font-weight: normal;
                color: #333;
            }
            p {
                line-height: 15px;
            }
            .name-info {
                font-size: 14px;
                margin-bottom: 5px;
                color: #888;
                font-weight: bold;
                line-height: 10px;
                margin-bottom: 10px;
            }
            .email-info {
                color: #999999;
                font-size: 12px;
                padding-top: 0;
                margin-bottom: 10px;
            }

            .profile-nav {
                padding-top: 10px;
                border-top: 1px solid #e3e3e3;
            }
            /*background-color: green;*/
        }
    }

</style>